import { Redirect, Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Markdown from "react-markdown";
import React, { useLayoutEffect } from "react";
import Row from "react-bootstrap/Row";

import CustomButton from "../../../common/components/button/custom-button";
import CustomCard from "../../../common/components/card/custom-card";
import CustomImage from "../../../common/components/image/custom-image";
import CustomText from "../../../common/components/text/custom-text";
import CustomTitle from "../../../common/components/title/custom-title";
import Layout from "../../../common/components/layout/layout";
import Subscribe from "../subscribe/subscribe";

import "../sections.scss";
import postlist from "../../../posts.json";

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Postlist = () => {
  return (
    <div className="post-list">
      <Row>
        {postlist.map((post, index) => {
          let source = post.content
            .replace(/\n\n/g, "<br/>")
            .replace(/\*/g, "")
            .replace(/\./g, ". ");
          if (index < 3) {
            return (
              <Col xs={12} lg={4} key={index + 1}>
                <Link to={`/newsletter/${post.id}`} className="link">
                  <CustomCard
                    src={post.thumbnail}
                    title={post.title}
                    text={
                      <Markdown
                        source={capitalizeFirstLetter(source)}
                        escapeHtml={false}
                      />
                    }
                    date={post.date}
                  />
                </Link>
              </Col>
            );
          } else return "";
        })}
      </Row>
    </div>
  );
};

const Post = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const fetchedPost = {};
  let postExists = false;
  postlist.forEach((post, i) => {
    if (validId === post.id) {
      fetchedPost.title = post.title ? post.title : "No title given";
      fetchedPost.date = post.date ? post.date : "No date given";
      fetchedPost.author = post.author ? post.author : "No author given";
      fetchedPost.thumbnail = post.thumbnail
        ? post.thumbnail
        : "No thumbnail given";
      fetchedPost.content = post.content ? post.content : "No content given";
      postExists = true;
    }
  });
  if (postExists === false) {
    return <Redirect to="/404" />;
  }

  return (
    <Layout>
      <div className="post">
        <div>
          <div className="promotion">
            <div>
              <CustomText
                className="custom-text-18"
                text="Enhance player valuation with powerful analytic intelligence by Sportslytx"
              />
            </div>
            <Link to="/get-in-touch">
              <CustomButton
                buttonTitle="Get Consultation"
                buttonSize="lg"
                buttonType="button"
              />
            </Link>
          </div>
          <div className="post-content">
            <div className="post-title-wrapper">
              <CustomTitle
                className="custom-title-32"
                text={fetchedPost.title}
              />
            </div>
            <CustomText className="custom-text-12" text={fetchedPost.date} />
            <CustomImage src={fetchedPost.thumbnail} className="thumbnail" />
            <Markdown
              source={fetchedPost.content}
              escapeHtml={false}
              linkTarget="_blank"
            />
          </div>
        </div>
        <div>
          <div className="posts-title">
            <CustomTitle
              className="custom-title-32"
              text="More from Sportslytx"
            />
          </div>
          {Postlist()}
        </div>
      </div>
      <Subscribe text="To get more insightful information about sports analytics to gain an edge" />
    </Layout>
  );
};

export default Post;

// import emailjs from "@emailjs/browser";
import React/* , { useState }*/ from "react";

// import CustomInputGroup from "../../../common/components/textfield/custom-input-group";
// import CustomSpinner from "../../../common/components/spinner/custom-spinner";
import CustomText from "../../../common/components/text/custom-text";
import CustomTitle from "../../../common/components/title/custom-title";

import "../sections.scss";

const Subscribe = (props) => {
  /*
  const [email, setEmail] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleFormData = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);

    emailjs
      .sendForm(
        "service_pr76gz7",
        "template_bz3905a",
        e.target,
        "user_cgI72zPEPpSMZpm6POsXi"
      )
      .then((result) => {
        setShowSpinner(false);
        setEmail("");
        alert("You've successfully subscribed to Sportslytx newsletters!");
      })
      .catch((error) => {
        setShowSpinner(false);
        alert("Subscription failed. Please try again.");
      });
  };
  */

  return (
    <div id="subscribe" className="subscribe-section">
      {/*showSpinner ? <CustomSpinner /> : null*/}
      <div className="subscribe-title-wrapper">
        <CustomTitle
          className="custom-title-43"
          text="Subscribe To Our Newsletter"
        />
      </div>
      <div>
        <CustomText className="custom-text-18" text={props.text} />
      </div>
      {/*<form onSubmit={(e) => handleSubmit(e)}>
        <CustomInputGroup
          type="email"
          placeholder="Enter your email"
          name="email"
          value={email}
          size="lg"
          handleFormData={(event) => handleFormData(event)}
          buttonType="submit"
          buttonTitle="Subscribe"
          disableButton={showSpinner}
          buttonSize="lg"
        />
      </form>*/}
      <iframe title="Subscribe" data-w-type="embedded" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://0xjkx.mjt.lu/wgt/0xjkx/7ox/form?c=afcb3cc4" width="100%" style={{height: '435px'}}></iframe>
      <script type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js"></script>
    </div>
  );
};

export default Subscribe;

import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import React from "react";

import "../components.scss";

function CustomButton(props) {
  return (
    <Button
      type={props.buttonType}
      variant={props.buttonVariant}
      size={props.buttonSize}
      disabled={props.disabledButton}
      onClick={props.handleSubmit}
      className="button-class"
    >
      {props.buttonTitle}
    </Button>
  );
}

CustomButton.propTypes = {
  buttonType: PropTypes.oneOf(["button", "reset", "submit", null]),
  buttonSize: PropTypes.string,
  buttonTitle: PropTypes.any,
  buttonVariant: PropTypes.string,
  disabledButton: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default CustomButton;

// --------- Import images ---------
import ABOUT_US from "../../../assets/images/about_us.webp";
import ERROR_404 from "../../../assets/images/error404.webp";
import FACEBOOK_ICON from "../../../assets/images/facebook_icon.svg";
import INSTAGRAM_ICON from "../../../assets/images/instagram_icon.svg";
import LINKEDIN_ICON from "../../../assets/images/linkedin_icon.svg";
import RECTANGLE_111 from "../../../assets/images/rectangle111.webp";
import RECTANGLE_55 from "../../../assets/images/rectangle55.webp";
import RECTANGLE_86 from "../../../assets/images/rectangle86.webp";
import RECTANGLE_86_FULL from "../../../assets/images/rectangle86_full.webp";
import RECTANGLE_86_MOBI from "../../../assets/images/rectangle86_mobile.webp";
import RECTANGLE_87 from "../../../assets/images/rectangle87.webp";
import RECTANGLE_87_FULL from "../../../assets/images/rectangle87_full.webp";
import RECTANGLE_87_MOBI from "../../../assets/images/rectangle87_mobile.webp";
import RECTANGLE_89 from "../../../assets/images/rectangle89.webp";
import RECTANGLE_89_FULL from "../../../assets/images/rectangle89_full.webp";
import RECTANGLE_89_MOBI from "../../../assets/images/rectangle89_mobile.webp";
import TWITTER_ICON from "../../../assets/images/twitter_icon.svg";

// --------- Exports ---------
const CustomImages = {
  ABOUT_US: ABOUT_US,
  ERROR_404: ERROR_404,
  FACEBOOK_ICON: FACEBOOK_ICON,
  INSTAGRAM_ICON: INSTAGRAM_ICON,
  LINKEDIN_ICON,
  RECTANGLE_111: RECTANGLE_111,
  RECTANGLE_55: RECTANGLE_55,
  RECTANGLE_86: RECTANGLE_86,
  RECTANGLE_86_FULL: RECTANGLE_86_FULL,
  RECTANGLE_86_MOBI: RECTANGLE_86_MOBI,
  RECTANGLE_87: RECTANGLE_87,
  RECTANGLE_87_FULL: RECTANGLE_87_FULL,
  RECTANGLE_87_MOBI: RECTANGLE_87_MOBI,
  RECTANGLE_89: RECTANGLE_89,
  RECTANGLE_89_FULL: RECTANGLE_89_FULL,
  RECTANGLE_89_MOBI: RECTANGLE_89_MOBI,
  TWITTER_ICON: TWITTER_ICON,
};
export default CustomImages;

import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ReactNavbar from "react-bootstrap/Navbar";

import CustomButton from "../button/custom-button";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";

import "../components.scss";

const Navbar = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [navBG, setNavBG] = useState("nav-clear");

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.onscroll = function () {
        if (document.documentElement.scrollTop > 70 && navBG === "nav-clear")
          setNavBG("nav-solid");
        if (document.documentElement.scrollTop <= 70 && navBG === "nav-solid")
          setNavBG("nav-clear");
      };
    } else {
      if (showMenu) setNavBG("nav-menu");
      else setNavBG("nav-solid");
    }
  }, [navBG, showMenu]);

  const renderCollapsed = () => {
    const currentTab = window.location.pathname;
    if (window.screen.width > 768) {
      return (
        <div className="pc-nav">
          <div className="nav-container">
            <Link className={currentTab === "/" ? "active" : ""} to="/">
              Home
            </Link>
            <hr />
            <Link
              className={currentTab === "/get-in-touch" ? "active" : ""}
              to="/get-in-touch"
            >
              Get Consultation
            </Link>
            <hr />
            <Link
              className={currentTab === "/sports/baseball" ? "active" : ""}
              to="/sports/baseball"
            >
              Baseball
            </Link>
            <hr />
            <Link
              className={currentTab === "/sports/basketball" ? "active" : ""}
              to="/sports/basketball"
            >
              Basketball
            </Link>
            <hr />
            <Link
              className={currentTab === "/sports/football" ? "active" : ""}
              to="/sports/football"
            >
              Football
            </Link>
            <hr />
            <Link
              className={currentTab === "/newsletter" ? "active" : ""}
              to="/newsletter"
            >
              Newsletters
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="menu-items-wrapper">
          <Link to="/get-in-touch">
            <CustomButton
              buttonTitle="Get Consultation"
              buttonVariant="button-outline"
            />
          </Link>
          <hr />
          <Link className="links menu-link" to="/sports/baseball">
            Baseball
          </Link>
          <hr />
          <Link className="links menu-link" to="/sports/basketball">
            Basketball
          </Link>
          <hr />
          <Link className="links menu-link" to="/sports/football">
            Football
          </Link>
          <hr />
          <Link className="links menu-link" to="/newsletter">
            Newsletters
          </Link>
        </div>
      );
    }
  };
  const renderBrand = () => {
    if (!showMenu || window.screen.width < 768)
      return (
        <ReactNavbar.Brand href="/" target="_self">
          <Logo />
        </ReactNavbar.Brand>
      );
  };
  const renderButton = () => {
    if (!showMenu)
      return (
        <div inline="true" className="ml-auto pc-consult">
          {props.showButton && (
            <Link to="/get-in-touch">
              <CustomButton buttonTitle="Get Consultation" />
            </Link>
          )}
        </div>
      );
  };
  const handleToggle = () => {
    setShowMenu(!showMenu);
    if (!showMenu) setNavBG("nav-menu");
    else {
      if (document.documentElement.scrollTop > 50) setNavBG("nav-solid");
      if (document.documentElement.scrollTop <= 50) setNavBG("nav-clear");
    }
  };
  return (
    <ReactNavbar
      id="myNav"
      collapseOnSelect
      expand="xxl"
      sticky="top"
      className={navBG}
    >
      {renderBrand()}
      {renderButton()}
      <ReactNavbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => handleToggle()}
      />
      <ReactNavbar.Collapse id="basic-navbar-nav">
        {renderCollapsed()}
      </ReactNavbar.Collapse>
    </ReactNavbar>
  );
};

export default Navbar;

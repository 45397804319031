import { IconContext } from "react-icons";
import PropTypes from "prop-types";
import React from "react";

function CustomIcon(props) {
  return (
    <IconContext.Provider
      value={{
        size: props.size ? props.size : "24px",
        className: props.className ? props.className : "",
      }}
    >
      {props.bgClass ? (
        <span className={props.bgClass}>{props.icon}</span>
      ) : (
        props.icon
      )}
    </IconContext.Provider>
  );
}

CustomIcon.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  bgClass: PropTypes.string,
  icon: PropTypes.element.isRequired,
};

export default CustomIcon;

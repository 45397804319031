import Image from "react-bootstrap/Image";
import PropTypes from "prop-types";
import React from "react";

import "../components.scss";

function CustomImage(props) {
  return (
    <Image
      src={props.src}
      roundedCircle={props.roundedCircle}
      alt={props.alt}
      width={props.width}
      height={props.height}
      className={props.className}
    />
  );
}

CustomImage.propTypes = {
  src: PropTypes.string,
  roundedCircle: PropTypes.bool,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

/* Exports ================================================================== */

export default CustomImage;

import React from "react";

import Footer from "../footer/custom-footer";
import Navbar from "../navbar/custom-navbar";

import "../components.scss";

const Layout = ({ showButton, children }) => {
  return (
    <div className="layout">
      <Navbar showButton={showButton} />
      <div className="layout-body">
        <div>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
import ReactGA from  "react-ga4";

import "bootstrap/dist/css/bootstrap.min.css";

import CustomSpinner from "./common/components/spinner/custom-spinner";
import Post from "./layouts/sections/post/post";

import "./App.scss";

const Baseball = lazy(() => import("./layouts/sports/baseball"));
const Basketball = lazy(() => import("./layouts/sports/basketball"));
const Football = lazy(() => import("./layouts/sports/football"));
const Home = lazy(() => import("./layouts/home/home"));
const Consult = lazy(() => import("./layouts/consult/consult"));
const Newsletter = lazy(() => import("./layouts/newsletter/newsletter"));
const ErrorPage = lazy(() => import("./layouts/error/errorPage"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/newsletter",
    exact: true,
    component: Newsletter,
  },
  {
    path: "/get-in-touch",
    exact: true,
    component: Consult,
  },
  {
    path: "/sports/baseball",
    exact: true,
    component: Baseball,
  },
  {
    path: "/sports/basketball",
    exact: true,
    component: Basketball,
  },
  {
    path: "/sports/football",
    exact: true,
    component: Football,
  },
  {
    path: "/newsletter/:id",
    exact: true,
    component: (props) => <Post {...props} />,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];

function App() {
  ReactGA.initialize("G-5HSFJLYDQ3");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  return (
    <Suspense fallback={() => <CustomSpinner />}>
      <Router>
        <div>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </div>
      </Router>
    </Suspense>
  );
}

export default App;

import { IoMdArrowRoundForward } from "react-icons/io";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import React from "react";

import CustomIcon from "../icon/custom-icon";
import CustomText from "../text/custom-text";
import CustomTitle from "../title/custom-title";

import "../components.scss";

function CustomCard(props) {
  return (
    <Card>
      <Card.Img variant="top" src={props.src} className={props.contain ? 'thumbnail-contain' : ''} />
      <Card.Body>
        <Card.Title>
          <CustomTitle text={props.title} />
        </Card.Title>
        <div className="card-text-wrapper">
          <CustomText text={props.text} className="custom-text-18" />
        </div>
        <div className="date-wrapper">
          <Card.Subtitle>{props.date}</Card.Subtitle>
          <CustomText
            text={
              <span>
                Read more <CustomIcon icon={<IoMdArrowRoundForward />} />
              </span>
            }
            className="custom-text-14"
          />
        </div>
      </Card.Body>
    </Card>
  );
}

CustomCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  contain: PropTypes.bool,
};

export default CustomCard;

import React from "react";

import "../components.scss";

function GSpinner() {
  return (
    <div className="spinner">
      <div className="cube1"></div>
      <div className="cube2"></div>
    </div>
  );
}

/* Exports ================================================================== */

export default GSpinner;

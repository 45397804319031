import PropTypes from "prop-types";
import React from "react";

import "../components.scss";

function CustomText(props) {
  return (
    <span className={props.className ? props.className : "custom-text-default"}>
      {props.text}
    </span>
  );
}

CustomText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.any.isRequired,
};

export default CustomText;

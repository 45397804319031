import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import React from "react";
import Row from "react-bootstrap/Row";

import CustomImage from "../image/custom-image";
import CustomImages from "../image/custom-images";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";

import "../components.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Row className="footer-row">
        <Col sm={4}>
          <Logo />
        </Col>
        <Col sm={4}>
          <CustomImage
            className="footer-icon"
            src={CustomImages.FACEBOOK_ICON}
            alt="Facebook Icon"
          />
          <a href="https://twitter.com/sportslytx" target="_blank" rel="noreferrer">
            <CustomImage
              className="footer-icon"
              src={CustomImages.TWITTER_ICON}
              alt="Twitter Icon"
            />
          </a>
          <CustomImage
            className="footer-icon"
            src={CustomImages.INSTAGRAM_ICON}
            alt="Instagram Icon"
          />
          <a href="http://www.linkedin.com/company/sportslytx" target="_blank" rel="noreferrer">
            <CustomImage
              className="footer-icon"
              src={CustomImages.LINKEDIN_ICON}
              alt="LinkedIn Icon"
            />
          </a>
        </Col>
        <Col sm={4}>
          <Link className="footer-links" to="/newsletter">
            Newsletters
          </Link>
          <hr />
          <Link className="footer-links" to="/get-in-touch">
            Get In Touch
          </Link>
          <hr />
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

import PropTypes from "prop-types";
import React from "react";

import "../components.scss";

function CustomTitle(props) {
  return (
    <span
      className={props.className ? props.className : "custom-title-default"}
      style={props.styles}
    >
      {props.text}
    </span>
  );
}

CustomTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CustomTitle;
